//TODO: Add all styles

body {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-size: 14px;
    color: #404040;
}

h6 {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
}
