@import './colors.scss';
$header-height: 60px;
$footer-height: 48px;
$min-body-height: calc(100vh - $header-height - $footer-height);

.dashboard-container {
  padding-top: 60px;
}
.header-container {
  height: $header-height;
}
.body-container {
  min-height: $min-body-height;
}

body.no-select * {
  user-select: none;
}

.AccountString {
  font-family: 'Courier New';
}

.navbar-expand .navbar-nav .dropdown-menu[aria-labelledby='user-action'] {
  transform: translateX(calc(-100% + 45px));
  top: 33px;
}

.navbar-light .navbar-nav .nav-link.disabled {
  path {
    stroke: #b7b7b7;
  }
}

.navbar {
  .navbar-nav .nav-link {
    // padding: 8px 16px;
  }
  .navbar-left-item {
    margin-right: 0rem;
  }
  .navbar-right-item {
    margin-left: 0.5rem;
  }

  .dropdown-toggle.nav-link:after {
    border: 0 !important;
  }

  h6 {
    color: #{nth($blue-grey, 1)};
    font-size: 12px;
    font-weight: 600;
    margin-left: 0.5rem;
    margin-top: 0;
    margin-bottom: 0;
  }
}

[aria-disabled='true'] {
  cursor: not-allowed !important;
  path {
    stroke: #b7b7b7 !important;
  }
}
.company-name-dispatcher {
  position: relative;
  color: var(--text-color);
  margin-bottom: 0;
  font-size: 14px;
  border-left: 1px solid lightgrey;
  padding-left: 5px;
  vertical-align: middle;
}

.custom-nav-wrapper {
  .right {
    position: absolute;
    right: 0px;
    padding: 10px;
  }
  align-items: center;
  .custom-avatar {
    background-color: #d0eeee;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    text-align: center;
    margin-left: 0.5rem;
    margin-right: 1rem;
    font-size: 10px;
    .nav-link {
      position: relative;
      color: #00a3a5;
      &:hover {
        color: #00a3a5;
      }
    }
    .custom-avatar-body {
      position: absolute;
      left: 6px;
      top: 5px;
      bottom: 0;
    }
  }
}
.nav-item {
  margin-left: 1rem;
  margin-right: 1rem;
}
.mr-auto {
  margin-right: auto;
}

.footer {
  position: relative;
  height: $footer-height;
  display: flex;
  align-items: center;
  justify-content: stretch;
  background: #{nth($light-teal, 3)};
  .copyright-notice {
    color: #{nth($blue-grey, 3)};
    margin: 1rem 1rem 1rem 1rem;
  }
  img {
    margin-left: auto;
  }
  .powered-by-notice {
    color: #{nth($blue-grey, 3)};
    margin: 1rem 1rem 1rem 1rem;
  }
}
