@import 'colors';
.product-group {
  margin: 0 -15px;
  text-align: center;
  padding: 66px 39px;
  position: relative;
  background-color: white;
  &.product-header {
    padding: 66px 250px;
    h3 {
      color: #{nth($blue-grey, 1)};
      font-size: 36px;
      font-weight: 600;
      margin-bottom: 32px;
    }
    h6 {
      color: #{nth($blue-grey, 4)};
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 32px;
    }
  }
  &:nth-child(2n) {
    background-color: #{nth($grey, 6)};
  }
  .btn-header {
    padding: 8px;
    width: 200px;
  }
  .nav-button {
    background: inherit !important;
    border: none !important;
    position: absolute;
    top: 0;
    height: 100%;
    opacity: 1 !important;
    &:disabled {
      svg path {
        fill-opacity: 50%;
      }
    }
    &.next-button {
      right: 0;
      padding-left: 16px;
      transform: rotateZ(180deg);
    }
    &.prev-button {
      padding-left: 16px;
      left: 0;
    }
  }
  h3 {
    color: #{nth($blue-grey, 1)};
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 32px;
  }
  h6 {
    color: #{nth($blue-grey, 4)};
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 32px;
  }
  .product-carousel {
    transition: all 300ms ease-out;
    overflow: visible !important;
    flex-wrap: nowrap !important;
    .product-carousel-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      h3 {
        font-size: 24px;
      }
      .btn {
        padding: 8px;
        width: 200px;
      }
    }
  }
}
