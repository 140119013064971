@import './colors.scss';

.product-group-dashbox {
  width: 100%;
  text-align: center;
  padding: 4rem 1.5rem;
  &:nth-child(2n) {
    background-color: #{nth($grey, 6)};
  }
  h3 {
    font-size: 36px;
    color: #{nth($blue-grey, 1)};
  }
  h6 {
    font-size: 18px;
    color: #{nth($blue-grey, 4)};
  }
}
