//TODO: Replace with actual component styles

.company-list {
  max-height: 60vh;
  overflow: auto;
  padding: 0px 24px;
}

h6.welcome-message {
  color: #506574;
  font-size: 24px;
  line-height: 30px;
  margin: 0 48px;
}

input::placeholder {
  color: #cfcfcf;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  font-style: italic;
}

.copyright-notice-login {
  font-size: 12px;
  position: relative;
  top: 16px;
  bottom: 16px;
  color: #6d899d;
  font-weight: 400;
  margin-left: 48px;
}

.login-banner {
  font-weight: bold;
  font-size: 48px;
  line-height: 48px;
  color: white;
}

.login-subtitle {
  font-size: 18px;
  color: white;
}

.bg-white {
  background-color: white !important;
}
